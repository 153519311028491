import React, { useCallback, useMemo, useState } from "react"
import {
    Avatar,
    Box,
    Button,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material"
import { RemoveRedEye } from "@mui/icons-material"
import MaterialReactTable from "material-react-table"
import { useQuery } from "react-query"
import { DateTime } from "luxon"
import { Link } from "react-router-dom"
import { useSnackbar } from "notistack"

import {
    SSO_API_BASE_URL,
    SSO_ASSETS_URL,
    SSO_VERSION,
} from "../../Utils/config.js"
import SearchReceiptsByTIN from "./searchReceiptsByTIN.jsx"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import customFilterPanel from "../../Component/customFilter.jsx"
import CustomExportOptions from "../../Component/customExport.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import handleReceiptExport from "../../Utils/File Export Formatter/receiptExportFormat.js"
import { formatToTwoDecimalPlaces } from "../../Utils/dataFormat.js"
import HasPermission from "../../Utils/access.js"

// List TINFilteredReceipts Component
function TINFilteredReceipts() {
    // USESTATE HOOK
    const [open, setOpen] = useState(false)
    const [filteredData, setFilteredData] = useState({
        data: [],
        filter: null,
    })
    const model = filteredData?.filter ? filteredData.filter : []

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()
    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        ["tin_filtered_receipts", model],
        () =>
            axiosPrivate.get(`/system/receipts`, {
                params: {
                    per_page: -1,
                    filter: JSON.stringify(model ?? undefined),
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: !!filteredData?.filter && !open,
        }
    )

    // HELPERS and EVENT HANDLERS
    const getAvatar = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1,
                }}
            >
                <Avatar
                    sx={{
                        width: 50,
                        height: 50,
                    }}
                    src={`${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${row?.original?.user?.profile_picture}`}
                />
                <Typography>{row?.original?.full_name ?? "-"}</Typography>
            </Box>
        ),
        []
    )
    const actions = useCallback(
        ({ row }) => (
            <Box sx={{ flex: 1 }}>
                <Link to={`${row.original.id}`}>
                    {HasPermission("get receipt") && (
                        <Tooltip title="View Receipt">
                            <IconButton
                                size="small"
                                sx={{ color: "primary.main" }}
                            >
                                <RemoveRedEye />
                            </IconButton>
                        </Tooltip>
                    )}
                </Link>
            </Box>
        ),
        []
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 280,
                accessorKey: "created_at",
                header: "Date",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            // {
            //     accessorKey: "fs_number",
            //     header: "FS No.",
            //     filterVariant: "number",
            //     renderColumnFilterModeMenuItems: customFilterPanel,
            // },
            {
                accessorKey: "receipt_number",
                header: "Invoice No.",
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell.getValue()?.toString().padStart(8, "0"),
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 240,
                accessorKey: "full_name",
                header: "Customer Name",
                Cell: getAvatar,
            },
            {
                size: 220,
                accessorKey: "phone",
                header: "Customer Phone",
                Cell: ({ row }) => row?.original?.user?.phone,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "service_number",
                header: "Buyer's TIN",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "amount",
                header: "Amount",
                filterVariant: "number",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "vat",
                header: "Tax",
                filterVariant: "number",
                Cell: ({ row }) => formatToTwoDecimalPlaces(row?.original?.vat),
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "method",
                header: "Method",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "type",
                header: "Type",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 280,
                accessorKey: "updated_at",
                header: "Last Updated",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                accessorKey: "action",
                header: "Action",
                enableSorting: false,
                flex: 1,
                enableColumnFilter: false,
                Cell: actions,
            },
        ],
        [actions, getAvatar]
    )
    // RENDER
    return (
        <Box>
            {open && (
                <SearchReceiptsByTIN
                    open={open}
                    setOpen={setOpen}
                    refetch={refetch}
                    filteredData={filteredData}
                    setFilteredData={setFilteredData}
                />
            )}
            <Box sx={{ height: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={data?.data?.data ?? []}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["action"],
                        },
                    }}
                    enableColumnActions={false}
                    enablePagination={false}
                    enableBottomToolbar={false}
                    enableRowSelection
                    renderTopToolbarCustomActions={() => (
                        <Box>
                            {HasPermission("register tin number") &&
                                HasPermission("upload tin number") && (
                                    <Button
                                        size="small"
                                        sx={{
                                            bgcolor: "primary.main",
                                            color: "white",
                                            px: 2,
                                            mx: 1,
                                            boxShadow: 2,
                                            " :hover": {
                                                bgcolor: "primary.main",
                                                color: "white",
                                            },
                                        }}
                                        onClick={() => setOpen(true)}
                                    >
                                        Search By TIN
                                    </Button>
                                )}
                        </Box>
                    )}
                    renderToolbarInternalActions={({ table }) => (
                        <CustomExportOptions
                            table={table}
                            model={model}
                            page="/system/receipts"
                            formatter={handleReceiptExport}
                        />
                    )}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        showSkeletons: isFetching,
                        model,
                    }}
                />
            </Box>
        </Box>
    )
}

export default TINFilteredReceipts
