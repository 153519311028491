import React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Avatar } from "@mui/material"
import { useLocation } from "react-router-dom"

import stamp from "../../../Assets/Hybride designs Stamp.png"
import useBreakPoints from "../../../Hooks/useBreakPoints.js"

function formatNumbers(num) {
    return `${num.toFixed(2)}`
}

function priceRow(qty, unit) {
    return qty * unit
}

function createRow(qty, unit) {
    const price = priceRow(qty, unit)
    return { qty, unit, price }
}

function subtotal(items) {
    return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0)
}

export default function CorporateRecieptInfo({
    serviceCharge,
    quantity,
    amount,
    vat,
}) {
    const { pathname } = useLocation()
    const description = pathname.includes("custom-receipts")
        ? "Customer"
        : "Corporate"
    const { sm } = useBreakPoints()

    const unitPrice = amount - serviceCharge - vat
    const rows = [createRow(quantity, formatNumbers(unitPrice))]
    const serviceChargerows = [
        createRow(quantity, formatNumbers(serviceCharge)),
    ]

    const invoiceSubtotal = subtotal(rows) + subtotal(serviceChargerows)

    return (
        <TableContainer component={Paper} sx={{ position: "relative" }}>
            <Table
                aria-label="spanning table"
                sx={{ bgcolor: "#fff", border: 1, borderColor: "#f2f2f2" }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell
                            align="center"
                            colSpan={4}
                            size="small"
                            sx={{
                                fontWeight: 900,
                                bgcolor: "#f2f2f2",
                                height: 10,
                            }}
                        >
                            Invoice Details
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell size="small" sx={{ fontWeight: 900 }}>
                            Desc
                        </TableCell>
                        <TableCell
                            size="small"
                            align="right"
                            sx={{ fontWeight: 900 }}
                        >
                            Qty.
                        </TableCell>
                        <TableCell
                            size="small"
                            align="right"
                            sx={{ fontWeight: 900 }}
                        >
                            Price
                        </TableCell>
                        <TableCell
                            size="small"
                            align="right"
                            sx={{ fontWeight: 900 }}
                        >
                            Amount (ETB)
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell size="small">{description}</TableCell>
                            <TableCell size="small" align="right">
                                {row.qty}
                            </TableCell>
                            <TableCell size="small" align="right">
                                {row.unit.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </TableCell>
                            <TableCell size="small" align="right">
                                {formatNumbers(row.price).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                    {serviceChargerows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell size="small">Service Charge</TableCell>
                            <TableCell size="small" align="right">
                                {row.qty}
                            </TableCell>
                            <TableCell size="small" align="right">
                                {row.unit.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </TableCell>
                            <TableCell size="small" align="right">
                                {formatNumbers(row.price).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell
                            size="small"
                            rowSpan={5}
                            sx={{
                                verticalAlign: "top",
                                justifyContent: "center",
                                pt: 2,
                                alignItems: "center",
                            }}
                        >
                            <Avatar
                                sx={{
                                    width: sm ? 150 : 150,
                                    height: sm ? 140 : 140,
                                    bgcolor: "transparent",
                                    position: "relative",
                                    left: "3.5em",
                                }}
                            >
                                <img
                                    src={stamp}
                                    alt="Stamp"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        position: "absolute",
                                    }}
                                />
                            </Avatar>
                        </TableCell>
                        <TableCell
                            size="small"
                            colSpan={2}
                            sx={{
                                fontWeight: 900,
                                verticalAlign: "bottom",
                                paddingBottom: 2,
                            }}
                        >
                            TXBL 1
                        </TableCell>
                        <TableCell
                            size="small"
                            align="right"
                            sx={{ verticalAlign: "bottom", paddingBottom: 2 }}
                        >
                            {formatNumbers(invoiceSubtotal).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            size="small"
                            colSpan={2}
                            sx={{
                                fontWeight: 900,
                                verticalAlign: "top",
                            }}
                        >
                            TAX 1(15%)
                        </TableCell>
                        <TableCell
                            size="small"
                            align="right"
                            sx={{ verticalAlign: "top" }}
                        >
                            {formatNumbers(vat).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            size="small"
                            colSpan={2}
                            sx={{
                                fontWeight: 900,
                                verticalAlign: "bottom",
                            }}
                        >
                            Total
                        </TableCell>
                        <TableCell
                            size="small"
                            align="right"
                            sx={{ verticalAlign: "bottom" }}
                        >
                            {formatNumbers(amount).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            size="small"
                            colSpan={2}
                            sx={{
                                fontWeight: 900,
                                verticalAlign: "bottom",
                            }}
                        >
                            Cash
                        </TableCell>
                        <TableCell
                            size="small"
                            align="right"
                            sx={{ verticalAlign: "bottom" }}
                        >
                            {formatNumbers(amount).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            size="small"
                            colSpan={2}
                            sx={{
                                fontWeight: 900,
                                verticalAlign: "bottom",
                            }}
                        >
                            Item#
                        </TableCell>
                        <TableCell
                            size="small"
                            align="right"
                            sx={{ verticalAlign: "bottom" }}
                        >
                            2
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
