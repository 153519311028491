import React, { useEffect, useRef, useState } from "react"
import { Clear, FilterList } from "@mui/icons-material"
import { Box, IconButton, Menu } from "@mui/material"

import CustomDateTimePicker from "./cutomDateTimePicker.jsx"

export function FilterMenu({ open, setOpen, anchorEl, children }) {
    return (
        <Menu anchorEl={anchorEl} open={open} onClose={() => setOpen(false)}>
            {children}
        </Menu>
    )
}

function CustomeDateTimeFilter(props) {
    const { table, column } = props

    const [open, setOpen] = useState(false)
    const [children, setChildren] = useState(null)
    const [value, setValue] = useState({
        value1: null,
        value2: null,
    })

    const ref = useRef()

    const filterValue = column.getFilterValue()

    useEffect(() => {
        if (
            table.getState().filterFns[column.id] === "between" &&
            value.value1 !== null &&
            value.value2 !== null
        ) {
            column.setFilterValue(`${value?.value1}|${value?.value2}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value.value1, value.value2])
    useEffect(() => {
        if (
            table.getState().filterFns[column.id] !== "between" &&
            filterValue?.length > 30
        ) {
            setValue({
                value1: null,
                value2: null,
            })
            column.setFilterValue(undefined)
        }
        if (
            table.getState().filterFns[column.id] === "between" &&
            (filterValue?.length < 30 || value.value1 === undefined)
        ) {
            column.setFilterValue(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        column,
        column.id,
        filterValue?.length,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        table.getState().filterFns[column.id],
        value.value1,
    ])

    return (
        <Box ref={ref} sx={{ display: "flex", flexDirection: "column" }}>
            <Box ref={ref} sx={{ display: "flex", alignItems: "center" }}>
                <FilterMenu
                    open={open}
                    setOpen={setOpen}
                    anchorEl={ref.current}
                >
                    {children}
                </FilterMenu>
                <CustomDateTimePicker
                    variant="standard"
                    date1={value?.value2}
                    value={
                        filterValue === undefined || filterValue?.length > 30
                            ? value?.value1
                            : filterValue
                    }
                    onChange={(date) =>
                        table.getState().filterFns[column.id] === "between"
                            ? setValue((prev) => ({
                                  value1: date,
                                  value2: prev?.value2,
                              }))
                            : column.setFilterValue(date)
                    }
                    size="small"
                    helperText={
                        table.getState().filterFns[column.id] === "between"
                            ? " "
                            : `Filter Mode: ${
                                  table.getState().filterFns[column.id]
                              }`
                    }
                    InputProps={{
                        startAdornment: (
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setChildren(
                                        column.columnDef.renderColumnFilterModeMenuItems(
                                            {
                                                column,
                                                onSelectFilterMode: (fmode) => {
                                                    table.setColumnFilterFns({
                                                        ...table.getState()
                                                            .filterFns,
                                                        [column.id]: fmode,
                                                    })
                                                    setOpen(false)
                                                },
                                            }
                                        )
                                    )
                                    setOpen(true)
                                }}
                                sx={{
                                    "&:hover": {
                                        cursor: "pointer",
                                    },
                                }}
                            >
                                <FilterList />
                            </IconButton>
                        ),
                    }}
                />
                <IconButton
                    onClick={() => {
                        column.setFilterValue(undefined)
                        setValue((prev) => ({
                            value1: null,
                            value2: prev?.value2,
                        }))
                    }}
                    sx={{ mb: ".3em" }}
                >
                    <Clear />
                </IconButton>
            </Box>
            <Box ref={ref} sx={{ display: "flex", alignItems: "center" }}>
                {table.getState().filterFns[column.id] === "between" && (
                    <>
                        <CustomDateTimePicker
                            variant="standard"
                            value={value?.value2}
                            date2={value?.value1}
                            onChange={(date) => {
                                setValue((prev) => ({
                                    value1: prev?.value1,
                                    value2: date,
                                }))
                            }}
                            size="small"
                            helperText={
                                table.getState().filterFns[column.id] ===
                                "between"
                                    ? `Filter Mode: ${
                                          table.getState().filterFns[column.id]
                                      }`
                                    : " "
                            }
                            InputProps={{
                                startAdornment: (
                                    <Box
                                        size="small"
                                        sx={{
                                            width: "34px",
                                            height: "34px",
                                            px: 2,
                                        }}
                                    />
                                ),
                            }}
                        />
                        <IconButton
                            onClick={() => {
                                column.setFilterValue(undefined)
                                setValue((prev) => ({
                                    value1: prev?.value1,
                                    value2: null,
                                }))
                            }}
                            sx={{ mb: ".3em" }}
                        >
                            <Clear />
                        </IconButton>
                    </>
                )}
            </Box>
        </Box>
    )
}

export default CustomeDateTimeFilter
