/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useState } from "react"
import {
    Avatar,
    Box,
    Button,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { DateTime } from "luxon"
import { useQuery } from "react-query"
import { useSnackbar } from "notistack"
import { Refresh } from "@mui/icons-material"

import {
    SSO_ASSETS_URL,
    SSO_API_BASE_URL,
    SSO_VERSION,
} from "../../Utils/config.js"
import HasPermission from "../../Utils/access.js"
import filterModel from "../../Utils/filterModel.js"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import { prevDate, roundToTwoDecimalPlaces } from "../../Utils/dataFormat.js"

import Unauthorized from "../../Component/unauthorized.jsx"
import customFilterPanel from "../../Component/customFilter.jsx"
import CustomExportOptions from "../../Component/customExport.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import { handleCommissionTransactionsExport } from "../../Utils/File Export Formatter/transactionsExport.js"

// Ride Credit List Component
function RideCredit() {
    // USESTATE HOOK
    const [columnFilters, setColumnFilters] = useState([
        {
            id: "created_at",
            value: prevDate,
            operator: "after",
        },
    ])
    const [filterFns, setFilterFns] = useState({
        created_at: "after",
        driver_name: "contains",
        driver_phone: "contains",
        amount: "=",
        type: "contains",
        status: "is",
        note: "contains",
        payment_type: "contains",
        method: "contains",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const model = filterModel(filterFns, columnFilters)?.map((item) => ({
        column_field: item.column_field,
        operator_value: item.operator_value,
        value:
            item.operator_value === "empty" ||
            item.operator_value === "notEmpty"
                ? ""
                : item.value,
    }))

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()
    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "ride_plus_credit_transactions",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
        ],
        () =>
            axiosPrivate.get(`/system/transactions/ride-commission`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    filter: JSON.stringify(model),
                    link_operator: "or",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
        }
    )

    // HELPERS and EVENT HANDLERS
    const getDriver = useCallback(
        ({ cell }) => (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1,
                }}
            >
                <Avatar
                    sx={{
                        width: 50,
                        height: 50,
                    }}
                    src={`${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${cell?.row?.original?.transaction?.detail?.driver_profile_picture}`}
                />
                <Typography>
                    {cell?.row?.original?.transaction?.detail?.driver_name}
                </Typography>
            </Box>
        ),
        []
    )
    const getType = useCallback(
        ({ cell }) => (
            <Button
                key={cell?.row?.original?.transaction?.id}
                size="small"
                disableElevation
                variant="contained"
                sx={{
                    backgroundColor: "#f5f5f5",
                    color: "#fbcf3b",
                    "&: hover": {
                        backgroundColor: "#f5f5f5",
                        color: "#fbcf3b",
                    },
                    fontWeight: "bold",
                }}
            >
                {cell?.row?.original?.transaction?.type}
            </Button>
        ),
        []
    )
    const getStatus = useCallback(
        ({ cell }) =>
            cell?.row?.original?.transaction?.status !== "" && (
                <Button
                    key={cell?.row?.original?.transaction?.id}
                    size="small"
                    disableElevation
                    variant="contained"
                    sx={{
                        backgroundColor:
                            cell?.row?.original?.transaction?.status ===
                            "APPROVED"
                                ? "#e5faea"
                                : "#ffd6d6",
                        color:
                            cell?.row?.original?.transaction?.status ===
                            "APPROVED"
                                ? "#33ca51"
                                : "red",
                        "&: hover": {
                            backgroundColor:
                                cell?.row?.original?.transaction?.status ===
                                "APPROVED"
                                    ? "#e5faea"
                                    : "#ffd6d6",
                            color:
                                cell?.row?.original?.transaction?.status ===
                                "APPROVED"
                                    ? "#33ca51"
                                    : "red",
                        },
                    }}
                >
                    {cell?.row?.original?.transaction?.status}
                </Button>
            ),
        []
    )
    const getPaymentType = useCallback(
        ({ cell }) => (
            <Box>
                {cell?.row?.original?.transaction?.detail?.type !== "" && (
                    <Button
                        key={cell?.row?.original?.transaction?.id}
                        size="small"
                        disableElevation
                        variant="contained"
                        sx={{
                            backgroundColor: "#f5f5f5",
                            color: "black",
                            "&: hover": {
                                backgroundColor: "#f5f5f5",
                                color: "black",
                            },
                            fontWeight: "bold",
                            textTransform: "none",
                        }}
                    >
                        {cell?.row?.original?.transaction?.detail?.type}
                    </Button>
                )}
            </Box>
        ),
        []
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 240,
                accessorKey: "created_at",
                header: "Date",
                filterVariant: "date",
                Cell: ({ cell }) =>
                    cell?.row?.original?.transaction?.created_at
                        ? DateTime.fromISO(
                              cell?.row?.original?.transaction?.created_at
                          ).toLocaleString(DateTime.DATETIME_MED)
                        : "",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Filter: datePicker,
            },
            {
                size: 240,
                accessorKey: "driver_name",
                header: "Driver",
                Cell: getDriver,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 180,
                accessorKey: "driver_phone",
                header: "Driver Phone",
                Cell: ({ cell }) =>
                    `${cell?.row?.original?.transaction?.detail?.driver_phone}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 180,
                accessorKey: "amount",
                header: "Amount",
                align: "left",
                headerAlign: "left",
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell?.row?.original?.transaction?.detail?.amount > 0
                        ? `${roundToTwoDecimalPlaces(
                              cell?.row?.original?.transaction?.detail?.amount
                          )}`
                        : `${cell?.row?.original?.transaction?.detail?.amount}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "type",
                header: "Type",
                filterVariant: "select",
                filterSelectOptions: ["TRANSFER", "DEPOSIT", "WITHDRAW"],
                Cell: getType,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "payment_type",
                header: "Payment Type",
                Cell: getPaymentType,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 180,
                accessorKey: "method",
                header: "Bank",
                Cell: ({ cell }) =>
                    cell?.row?.original?.transaction?.detail?.method
                        ? `${cell?.row?.original?.transaction?.detail?.method}`
                        : " - ",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: ["APPROVED", "PENDING", "REJECTED"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
        ],
        [getDriver, getPaymentType, getStatus, getType]
    )

    // RENDER
    if (!HasPermission("list ride commission transactions"))
        return <Unauthorized />

    return (
        <Box sx={{ height: "100%" }}>
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={data?.data?.data ?? []}
                    initialState={{
                        pagination: pagination,
                        showColumnFilters: true,
                        showGlobalFilter: globalFilter ?? false,
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["status"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                        disabled:
                            filterFns[column?.id] === "notEmpty" ||
                            filterFns[column?.id] === "empty",
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={model}
                                page="/system/transactions/ride-commission"
                                formatter={handleCommissionTransactionsExport}
                                linkOperator="and"
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}

export default RideCredit
