/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import {
    Alert,
    Box,
    Button,
    FormControlLabel,
    IconButton,
    Switch,
    Tooltip,
} from "@mui/material"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"
import { useMutation, useQuery } from "react-query"
import { Refresh, RemoveRedEye } from "@mui/icons-material"

import {
    getPreviousDateTime,
    roundToThreeDecimalPlaces,
    roundToTwoDecimalPlaces,
} from "../../Utils/dataFormat.js"
import HasPermission from "../../Utils/access.js"
import filterModel from "../../Utils/filterModel.js"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import handleTripsExport from "../../Utils/File Export Formatter/tripsExportFormatter.js"

import EndTrip from "../../Component/endTripButton.jsx"
import Unauthorized from "../../Component/unauthorized.jsx"
import GenerateStreetPickupReport from "./generateReport.jsx"
import customFilterPanel from "../../Component/customFilter.jsx"
import CustomExportOptions from "../../Component/customExport.jsx"
import CustomeDateTimeFilter from "../../Component/customDateTimeFilterPanel.jsx"

function StreetPickup() {
    // USESTATE HOOK
    const [columnFilters, setColumnFilters] = useState([
        {
            id: "created_at",
            value: getPreviousDateTime(),
            operator: "after",
        },
    ])
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [filterFns, setFilterFns] = useState({
        created_at: "after",
        start_point_name: "contains",
        end_point_name: "contains",
        start_time: "is",
        end_time: "is",
        passenger_phone_number: "contains",
        phone: "contains",
        distance: "=",
        estimated_cost: "=",
        cost: "=",
        status: "is",
        commission_value: "=",
    })
    const [open, setOpen] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const trip = useRef({})

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    const navigate = useNavigate()

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()
    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            ["street_pickup"],
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
        ],
        () =>
            axiosPrivate.get(`/system/trips`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter: JSON.stringify(
                        filterModel(filterFns, columnFilters) ?? undefined
                    ),
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
        }
    )
    const {
        mutate: endTrip,
        isLoading: endTripIsLoading,
        error: endTripError,
    } = useMutation(
        (selectedTrip) =>
            axiosPrivate.patch(`/system/trips/${selectedTrip?.id}/end`),
        {
            onSuccess: () => {
                enqueueSnackbar(`Ended the selected trip.`, {
                    variant: "success",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                })
                refetch()
                setOpenDialog(false)
            },
            onError: () =>
                endTripError?.response?.data?.error?.field_error?.length > 0
                    ? endTripError?.response?.data?.error?.field_error?.map(
                          (msg) =>
                              enqueueSnackbar(
                                  msg.description || "Network Error!",
                                  {
                                      variant: "error",
                                      preventDuplicate: true,
                                      autoHideDuration: 2000,
                                  }
                              )
                      )
                    : enqueueSnackbar(
                          endTripError?.response?.data?.error?.message ||
                              endTripError?.message ||
                              "Network Error!",
                          {
                              variant: "error",
                              preventDuplicate: true,
                              autoHideDuration: 2000,
                          }
                      ),
        }
    )
    const { mutate, isLoading: updateIsLoading } = useMutation(
        (tripData) =>
            axiosPrivate.patch(`/system/trips/${tripData.id}/paid`, {
                trip_type: "Ride Plus Trip",
            }),
        {
            onSuccess: () => {
                enqueueSnackbar("Status Updated Successfully!", {
                    variant: "success",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                })
                refetch()
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    { variant: "error" }
                )
            },
        }
    )
    console.log("check for multiple renders")
    // HELPERS and EVENT HANDLERS
    const handleSwitch = useCallback(
        (tripData) => {
            const tripStatus = {
                id: tripData.id,
            }
            mutate(tripStatus)
        },
        [mutate]
    )
    const handleClick = (selectedTrip) => {
        setOpenDialog(true)
        trip.current = selectedTrip
    }
    const statusBgColor = (status) => {
        switch (status) {
            case "STARTED":
                return "#F5F5F5"
            case "ENDED":
                return "#E5FFEA"
            case "CANCELLED":
                return "#FFE5E5"
            case "CREATED":
                return "#EFEFEF"
            default:
                return "white"
        }
    }
    const statusColor = (status) => {
        switch (status) {
            case "STARTED":
                return "#FFDC00"
            case "ENDED":
                return "#16C138"
            case "CANCELLED":
                return "#E11212"
            case "CREATED":
                return "#000000"
            default:
                return "text.primary"
        }
    }
    const getStatus = useCallback(
        ({ cell }) => (
            <Box
                sx={{
                    width: "130px",
                }}
            >
                <Alert
                    severity="info"
                    icon={false}
                    sx={{
                        justifyContent: "center",
                        borderRadius: "10px",
                        textTransform: "capitalize",
                        backgroundColor: `${statusBgColor(
                            cell?.row?.original?.status
                        )}`,
                        color: `${statusColor(cell?.row?.original?.status)}`,
                        fontWeight: "bold",
                    }}
                >
                    {cell?.row?.original?.status.toLowerCase()}
                </Alert>
            </Box>
        ),
        []
    )
    const getPaymentStatus = useCallback(
        ({ cell }) => (
            <FormControlLabel
                labelPlacement="start"
                label={cell?.getValue() ? "PAID" : "UNPAID"}
                checked={cell?.getValue()}
                disabled={
                    !HasPermission("mark trip as paid") ||
                    cell?.getValue() ||
                    updateIsLoading
                }
                onChange={() => handleSwitch(cell?.row?.original)}
                control={
                    <Switch
                        disableRipple
                        disabled={
                            !HasPermission("mark trip as paid") ||
                            cell?.getValue() ||
                            updateIsLoading
                        }
                        size="small"
                        sx={{
                            "& .MuiSwitch-switchBase": {
                                "&.Mui-checked": {
                                    "& + .MuiSwitch-track": {
                                        backgroundColor: "#c3eacb",
                                    },
                                    "& .MuiSwitch-thumb": {
                                        color: "#33ca51",
                                    },
                                    "&.Mui-disabled + .MuiSwitch-thumb": {
                                        backgroundColor: "lightseagreen",
                                        color: "lightgreen",
                                        opacity: 0.6,
                                    },
                                },
                            },
                            "& .MuiSwitch-thumb": {
                                color: "#f86049b5",
                            },
                            "& .MuiSwitch-track": {
                                backgroundColor: "#d9acac",
                            },
                        }}
                    />
                }
                sx={{
                    backgroundColor: cell.getValue() ? "#e5ffea" : "#ffd6d6",
                    opacity: updateIsLoading ? 0.4 : 1,
                    color: cell.getValue() ? "#33ca51" : "#f86049b5",
                    px: ".5em",
                    py: ".5em",
                    borderRadius: 1,
                    width: "17ch",
                    fontWeight: 900,
                }}
            />
        ),
        [handleSwitch, updateIsLoading]
    )
    const getActions = useCallback(
        ({ cell }) => (
            <Box>
                <IconButton
                    disabled={!HasPermission("view street pickup details")}
                    onClick={() =>
                        navigate(`${cell?.row?.original?.id}/tripDetails`, {
                            state: {
                                passenger_phone_number:
                                    cell?.row?.original?.passenger_phone_number,
                                paginationState: {
                                    pageIndex: pagination?.pageIndex,
                                    pageSize: pagination?.pageSize,
                                },
                                globalFilterState: globalFilter,
                                sortingState: sorting,
                                columnFiltersState: columnFilters,
                                filterFnsState: filterFns,
                            },
                        })
                    }
                    sx={{
                        mr: 1,
                    }}
                >
                    <RemoveRedEye
                        sx={{
                            cursor: "pointer",
                            color: HasPermission("view street pickup details")
                                ? "primary.main"
                                : "#9f9b94",
                        }}
                    />
                </IconButton>
                {HasPermission("end a street pickup service from system") && (
                    <EndTrip
                        data={cell?.row?.original}
                        handleClick={handleClick}
                        openDialog={openDialog}
                        setOpenDialog={setOpenDialog}
                        trip={trip}
                        endTrip={endTrip}
                        endTripIsLoading={endTripIsLoading}
                    />
                )}
            </Box>
        ),
        [endTripIsLoading, openDialog]
    )
    const datePicker = (props) => <CustomeDateTimeFilter {...props} />

    // USEEFFECT
    useEffect(() => {
        if (endTripError) {
            setOpenDialog(false)
        }
    }, [endTripError])

    // Data Structure
    const columns = useMemo(
        () => [
            {
                size: 280,
                accessorKey: "created_at",
                header: "Create at",
                filterVariant: "date",
                Cell: ({ cell }) =>
                    cell?.getValue()
                        ? DateTime.fromISO(cell?.getValue()).toLocaleString(
                              DateTime.DATETIME_MED
                          )
                        : "",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Filter: datePicker,
            },
            {
                size: 230,
                accessorKey: "start_point_name",
                header: "From",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "end_point_name",
                header: "To",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 280,
                accessorKey: "start_time",
                header: "Start Time",
                filterVariant: "date",
                Cell: ({ cell }) =>
                    cell?.getValue()
                        ? DateTime.fromISO(cell?.getValue()).toLocaleString(
                              DateTime.DATETIME_MED
                          )
                        : "",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Filter: datePicker,
            },
            {
                size: 280,
                accessorKey: "end_time",
                header: "End Time",
                filterVariant: "date",
                Cell: ({ cell }) =>
                    cell?.getValue() && cell?.row?.original?.status === "ENDED"
                        ? DateTime.fromISO(cell?.getValue()).toLocaleString(
                              DateTime.DATETIME_MED
                          )
                        : "",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Filter: datePicker,
            },
            {
                size: 220,
                accessorKey: "passenger_phone_number",
                header: "Passenger No ",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "phone",
                header: "Driver No",
                Cell: ({ cell }) => cell?.row?.original?.driver_info?.phone,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "distance",
                header: "Distance",
                filterVariant: "number",
                align: "center",
                headerAlign: "center",
                Cell: ({ cell }) =>
                    cell?.getValue() > 1000
                        ? `${roundToThreeDecimalPlaces(
                              Number(cell?.getValue()) / 1000
                          )} km`
                        : `${roundToTwoDecimalPlaces(cell?.getValue())} m`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "estimated_cost",
                header: "Estimated Price",
                filterVariant: "number",
                align: "center",
                headerAlign: "center",
                Cell: ({ cell }) =>
                    cell?.getValue() > 0
                        ? `${roundToTwoDecimalPlaces(cell?.getValue())} ETB`
                        : `${cell?.getValue()} ETB`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 180,
                accessorKey: "cost",
                header: "Price",
                filterVariant: "number",
                align: "center",
                headerAlign: "center",
                Cell: ({ cell }) =>
                    cell?.getValue() > 0
                        ? `${roundToTwoDecimalPlaces(cell?.getValue())} ETB`
                        : `${cell?.getValue()} ETB`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "commission_value",
                header: "Commission",
                filterVariant: "number",
                align: "center",
                headerAlign: "center",
                Cell: ({ cell }) =>
                    `${roundToTwoDecimalPlaces(cell?.getValue() ?? 0)}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "payment_status",
                header: "Payment Status",
                filterVariant: "select",
                Cell: getPaymentStatus,
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                align: "center",
                headerAlign: "center",
                filterVariant: "select",
                filterSelectOptions: [
                    "STARTED",
                    "ENDED",
                    "CANCELLED",
                    "CREATED",
                ],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "action",
                header: "Actions",
                size: 180,
                enableColumnFilter: false,
                enableSorting: false,
                filterVariant: "actions",
                Cell: getActions,
            },
        ],
        [getActions, getPaymentStatus, getStatus]
    )
    if (!HasPermission("list street pickup trips")) {
        return <Unauthorized />
    }

    return (
        <Box sx={{ width: "100%" }}>
            {open && (
                <GenerateStreetPickupReport open={open} setOpen={setOpen} />
            )}
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={data?.data?.data ?? []}
                    initialState={{
                        pagination: pagination,
                        showColumnFilters: true,
                        showGlobalFilter: globalFilter ?? false,
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["action"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderTopToolbarCustomActions={() => (
                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            {HasPermission("get street pickup report") && (
                                <Button
                                    size="small"
                                    sx={{
                                        bgcolor: "primary.main",
                                        color: "white",
                                        px: 2,
                                        mx: 1,
                                        boxShadow: 2,
                                        " :hover": {
                                            bgcolor: "primary.main",
                                            color: "white",
                                        },
                                    }}
                                    onClick={() => setOpen(true)}
                                >
                                    Generate Report
                                </Button>
                            )}
                        </Box>
                    )}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={filterModel(filterFns, columnFilters)}
                                page="/system/trips"
                                formatter={handleTripsExport}
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}

export default StreetPickup
