import React, { useContext, useEffect, useState } from "react"
import {
    AppBar as MuiAppBar,
    Toolbar,
    IconButton,
    Typography,
    Avatar,
    Menu,
    Divider,
    Drawer as MuiDrawer,
    List,
    Button,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Collapse,
    CircularProgress,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { Box, styled } from "@mui/system"
import {
    AccountBalance,
    Business,
    ContactEmergency,
    Description,
    DirectionsCar,
    EmojiTransportation,
    Error,
    ExpandLess,
    ExpandMore,
    Groups,
    Inventory,
    Logout,
    Loyalty,
    ManageAccounts,
    MenuOpen,
    Notifications,
    Person,
    Person2TwoTone,
    PointOfSale,
    Receipt,
    ReceiptLong,
    Settings,
    Summarize,
} from "@mui/icons-material"
import { useLocation, Outlet } from "react-router-dom"
import { useQuery } from "react-query"
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode"
import RideLogo from "../Assets/ride_logo.png"
import RideCredit from "../Assets/RIDE credit.svg"
import RidePlusCredit from "../Assets/RIDE Plus Credit.svg"
import RideCreditSelected from "../Assets/RIDE credit Selected.svg"
import RidePlusCreditSelected from "../Assets/RIDE Plus Credit Selected.svg"
import Link from "../Component/link.jsx"
import Page from "../Component/breadCrumbs.jsx"
import Driver from "../Assets/driver.jsx"
import useAxiosPrivate from "../Hooks/useAxiosPrivate.js"
import AuthContext from "../Context/authProvider.jsx"
import HasPermission from "../Utils/access.js"
import {
    ADMIN_BASE_URL,
    SSO_API_BASE_URL,
    SSO_ASSETS_URL,
    SSO_BASE_URL,
    SSO_VERSION,
} from "../Utils/config.js"
import Unauthorized from "../Component/unauthorized.jsx"
import ErrorPage from "../Component/errorPage.jsx"
import inverseLogo from "../Assets/inverse_logo.svg"

const drawerWidth = 240

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 2px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 3px)`,
    },
})

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}))

export default function Main() {
    const { auth, setPermissions } = useContext(AuthContext)
    const [open, setOpen] = useState(true)
    const [openMangementSetting, setOpenMangementSetting] = useState(true)
    const [openReceipts, setOpenReceipts] = useState(true)
    const [selected, setSelected] = useState({
        id: null,
        state: false,
    })

    const location = useLocation()
    const [anchorEl, setAnchorEl] = useState(null)
    const openMenu = Boolean(anchorEl)

    const decoded = jwt_decode(auth?.id_token)

    const axiosPrivate = useAxiosPrivate()

    const {
        data: permissions,
        isLoading,
        error,
    } = useQuery(["user-permissions"], () =>
        axiosPrivate.get(`/system/users/permissions`)
    )

    // EVENT HANDLERS & HELPERS
    const handleListItemSelect = (item) => {
        if (item === "Ride Commission") {
            setSelected({
                id: 1,
                state: true,
            })
        } else if (item === "Plus Commission") {
            setSelected({
                id: 2,
                state: true,
            })
        } else {
            setSelected({
                id: null,
                state: false,
            })
        }
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleSignOut = () => {
        window.location.href = `${SSO_BASE_URL}/rpLogout?post_logout_redirect_uri=${ADMIN_BASE_URL}/login`
        // window.location.href = `${SSO_BASE_URL}/v1/oauth/logout?post_logout_redirect_uri=${ADMIN_BASE_URL}/login`

        window.localStorage.clear("refresh token")
    }
    const handleManagementSettingClick = () => {
        setOpenMangementSetting(!openMangementSetting)
        setSelected({
            id: null,
            state: false,
        })
    }
    const handleReceipts = () => {
        setOpenReceipts(!openReceipts)
        setSelected({
            id: null,
            state: false,
        })
    }
    useEffect(() => {
        const permissionData = permissions?.data?.data?.map((item) => item.name)
        if (permissions?.data?.data && permissions?.data?.data.length !== 0) {
            setPermissions(permissionData)
        }
    }, [permissions, setPermissions])

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    minHeight: "450px",
                }}
            >
                <CircularProgress
                    size={40}
                    sx={{
                        color: "black",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                    }}
                />
            </Box>
        )
    }

    if (error) {
        return <ErrorPage error={error} />
    }

    if (
        permissions?.data?.data === null ||
        permissions?.data?.data.length === 0
    ) {
        return <Unauthorized />
    }

    return (
        <Box sx={{ display: "flex" }}>
            <AppBar
                position="fixed"
                color="secondary"
                open={open}
                sx={{
                    boxShadow: "0px 2px 5px #00000029",
                    background: " #FFFFFF 0% 0% no-repeat padding-box",
                    py: 0.7,
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpen(!open)}
                        edge="start"
                        sx={{
                            marginRight: "36px",
                            ...(open && { display: "none" }),
                        }}
                    >
                        {open ? null : <MenuIcon />}
                    </IconButton>
                    <Page location={location} />
                    {location.pathname === "/TIN-filtered-receipts" && (
                        <Box
                            sx={{
                                pt: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    mt: "-0.5em",
                                }}
                            >
                                Powered By
                            </Typography>
                            <Avatar
                                alt="inverse"
                                variant="square"
                                src={inverseLogo}
                                sx={{ width: 240, mx: "-2em" }}
                            />
                        </Box>
                    )}
                    <Box flex={1} />
                    <IconButton
                        size="large"
                        aria-label="display more actions"
                        edge="end"
                        color="inherit"
                        onClick={handleClick}
                    >
                        <Avatar
                            sx={{
                                height: "40px",
                                width: "40px",
                            }}
                            variant="circular"
                            src={`${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${decoded?.picture}`}
                        />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: "visible",
                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                        }}
                        anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                mt: 2,
                                mb: 3,
                                px: 2,
                                width: 220,
                            }}
                        >
                            <Avatar
                                sx={{
                                    width: 110,
                                    height: 110,
                                    mb: 2,
                                }}
                                variant="circular"
                                src={`${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${decoded?.picture}`}
                            />
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    maxWidth: 300,
                                }}
                                noWrap
                            >
                                {`${decoded?.first_name} ${decoded?.middle_name}`}
                            </Typography>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: "gray",
                                    maxWidth: 300,
                                }}
                                noWrap
                            >
                                {decoded?.email}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: 2,
                                mb: 1,
                                px: 2,
                            }}
                        >
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{
                                    bgcolor: "#f5f5f5",
                                    ":hover": { bgcolor: "#f5f5f5" },
                                    mx: 3,
                                    textTransform: "none",
                                }}
                                size="small"
                                onClick={handleSignOut}
                                startIcon={<Logout fontSize="small" />}
                            >
                                Sign Out
                            </Button>
                        </Box>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Box
                    sx={{
                        flex: 1,
                        background: "#FFFFFF 10% 0% no-repeat padding-box",
                        boxShadow: "10px 3px 10px #00000029",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            height: "76px",
                            background: "#FAFAFA 0% 0% no-repeat padding-box ",
                            boxShadow: "0px 3px 10px #00000029",
                        }}
                    >
                        <Typography
                            noWrap
                            component="div"
                            sx={{
                                ml: 1.3,
                                fontSize: "23px",
                                fontWeight: "bolder",
                            }}
                        >
                            RIDE PLUS
                        </Typography>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setOpen(!open)}
                            edge="start"
                            size="large"
                            sx={{
                                marginRight: "6px",
                            }}
                        >
                            {open ? <MenuOpen /> : <MenuIcon />}
                        </IconButton>
                    </Box>
                    <Avatar
                        variant="square"
                        sx={{
                            height: "auto",
                            width: open ? 128 : 36,
                            mx: "auto",
                            my: open ? 2 : 1,
                            transition: (theme) =>
                                theme.transitions.create(
                                    ["width", "height", "margin"],
                                    {
                                        easing: theme.transitions.easing.sharp,
                                        duration:
                                            theme.transitions.duration
                                                .enteringScreen,
                                    }
                                ),
                        }}
                        src={RideLogo}
                    />
                    <Divider
                        sx={{
                            width: "100%",
                            height: "12px",
                            color: "#DBDBDB",
                        }}
                    />
                    <List>
                        {HasPermission("list companies") && (
                            <Link
                                to="/corporates"
                                icon={<Business />}
                                label="Corporates"
                                open={open}
                                handleSelect={() =>
                                    handleListItemSelect("Corporates")
                                }
                            />
                        )}
                        {HasPermission("get list of drivers") && (
                            <Link
                                to="/drivers"
                                icon={<Driver />}
                                label="Drivers"
                                open={open}
                                handleSelect={() =>
                                    handleListItemSelect("Drivers")
                                }
                            />
                        )}
                        {HasPermission("list all users") && (
                            <Link
                                to="/personals"
                                icon={<Person />}
                                label="Personals"
                                open={open}
                                handleSelect={() =>
                                    handleListItemSelect("Personals")
                                }
                            />
                        )}
                        {(HasPermission("get all roles") ||
                            HasPermission("get system users") ||
                            HasPermission("get chart of accounts")) && (
                            <ListItemButton
                                onClick={handleManagementSettingClick}
                            >
                                <ListItemIcon sx={{ color: "inherit" }}>
                                    <Settings />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ color: "inherit" }}
                                    primary="Management"
                                />
                                {openMangementSetting ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItemButton>
                        )}
                        <Collapse
                            in={!openMangementSetting}
                            timeout="auto"
                            unmountOnExit
                        >
                            {HasPermission("get all roles") && (
                                <Link
                                    to="/roles"
                                    icon={<ManageAccounts />}
                                    label="Roles"
                                    open={openMangementSetting}
                                    sx={{ pl: open ? 6 : 3.5 }}
                                    handleSelect={() =>
                                        handleListItemSelect("Roles")
                                    }
                                />
                            )}
                            {HasPermission("get system users") && (
                                <Link
                                    to="/users"
                                    icon={<Person2TwoTone />}
                                    label="Users"
                                    open={openMangementSetting}
                                    sx={{ pl: open ? 6 : 3.5 }}
                                    handleSelect={() =>
                                        handleListItemSelect("Users")
                                    }
                                />
                            )}
                            {HasPermission("get chart of accounts") && (
                                <Link
                                    to="/coa"
                                    icon={<AccountBalance />}
                                    label="COA Setting"
                                    open={openMangementSetting}
                                    sx={{ pl: open ? 6 : 3.5 }}
                                    handleSelect={() =>
                                        handleListItemSelect("COA Setting")
                                    }
                                />
                            )}
                        </Collapse>
                        {HasPermission("list street pickup trips") && (
                            <Link
                                to="/street-pickup"
                                icon={<DirectionsCar />}
                                label="Street Pickup"
                                open={open}
                                handleSelect={() =>
                                    handleListItemSelect("Street Pickup")
                                }
                            />
                        )}
                        {HasPermission("list ride commission transactions") && (
                            <Link
                                to="/ride-commission"
                                icon={
                                    selected.id === 1 && selected.state ? (
                                        <Avatar
                                            sx={{ width: 24, height: 24 }}
                                            src={RideCredit}
                                        />
                                    ) : (
                                        <Avatar
                                            sx={{ width: 24, height: 24 }}
                                            src={RideCreditSelected}
                                        />
                                    )
                                }
                                label="Ride Commission"
                                open={open}
                                handleSelect={() =>
                                    handleListItemSelect("Ride Commission")
                                }
                            />
                        )}
                        {HasPermission("list plus commission transactions") && (
                            <Link
                                to="/plus-commission"
                                icon={
                                    selected.id === 2 && selected.state ? (
                                        <Avatar
                                            sx={{ width: 24, height: 24 }}
                                            src={RidePlusCreditSelected}
                                        />
                                    ) : (
                                        <Avatar
                                            sx={{ width: 24, height: 24 }}
                                            src={RidePlusCredit}
                                        />
                                    )
                                }
                                label="Plus Commission"
                                open={open}
                                handleSelect={() =>
                                    handleListItemSelect("Plus Commission")
                                }
                            />
                        )}
                        {HasPermission("get ride trips") && (
                            <Link
                                to="/trips"
                                icon={<EmojiTransportation />}
                                label="Trips"
                                open={open}
                                handleSelect={() =>
                                    handleListItemSelect("Trips")
                                }
                            />
                        )}
                        {HasPermission("get all agents") && (
                            <Link
                                to="/agents"
                                icon={<ContactEmergency />}
                                label="Agents"
                                open={open}
                                handleSelect={() =>
                                    handleListItemSelect("Agents")
                                }
                            />
                        )}
                        {HasPermission("get e-float request") && (
                            <Link
                                to="/efloat-topup-transactions"
                                icon={<PointOfSale />}
                                label="Efloat TopUp Transactions"
                                open={open}
                                handleSelect={() =>
                                    handleListItemSelect(
                                        "Efloat TopUp Transactions"
                                    )
                                }
                            />
                        )}
                        {HasPermission("get package transaction") && (
                            <Link
                                to="/packages"
                                icon={<Inventory />}
                                label="Packages"
                                open={open}
                                handleSelect={() =>
                                    handleListItemSelect("Packages")
                                }
                            />
                        )}
                        {(HasPermission("get all driver receipt") ||
                            HasPermission("get all corporate receipts") ||
                            HasPermission("get all custom receipts")) && (
                            <ListItemButton onClick={handleReceipts}>
                                <ListItemIcon sx={{ color: "inherit" }}>
                                    <Receipt />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ color: "inherit" }}
                                    primary="Receipts"
                                />
                                {openReceipts ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        )}
                        <Collapse
                            in={!openReceipts}
                            timeout="auto"
                            unmountOnExit
                        >
                            {HasPermission("get all driver receipt") && (
                                <Link
                                    to="/receipts"
                                    icon={<Driver />}
                                    label="Driver"
                                    open={openReceipts}
                                    handleSelect={() =>
                                        handleListItemSelect("Receipts")
                                    }
                                    sx={{ pl: open ? 6 : 3.5 }}
                                />
                            )}
                            {HasPermission("get all corporate receipts") && (
                                <Link
                                    to="/corporate-receipts"
                                    icon={<Business />}
                                    label="Corporate"
                                    open={openReceipts}
                                    handleSelect={() =>
                                        handleListItemSelect(
                                            "Corporate Receipts"
                                        )
                                    }
                                    sx={{ pl: open ? 6 : 3.5 }}
                                />
                            )}
                            {HasPermission("get all custom receipts") && (
                                <Link
                                    to="/custom-receipts"
                                    icon={<Description />}
                                    label="Custom"
                                    open={openReceipts}
                                    handleSelect={() =>
                                        handleListItemSelect("Custom Receipts")
                                    }
                                    sx={{ pl: open ? 6 : 3.5 }}
                                />
                            )}
                        </Collapse>
                        <Link
                            to="/TIN-filtered-receipts"
                            icon={<ReceiptLong />}
                            label="TIN Filtered Receipts"
                            open={open}
                            handleSelect={() =>
                                handleListItemSelect("TIN Filtered Receipts")
                            }
                        />
                        {HasPermission("list family program owners") && (
                            <Link
                                to="/family-package"
                                icon={<Groups />}
                                label="Family Package"
                                open={open}
                                handleSelect={() =>
                                    handleListItemSelect("Family Package")
                                }
                            />
                        )}
                        {HasPermission("get all loyalties") && (
                            <Link
                                to="/loyalties"
                                icon={<Loyalty />}
                                label="Loyalties"
                                open={open}
                                handleSelect={() =>
                                    handleListItemSelect("Loyalties")
                                }
                            />
                        )}
                        {HasPermission("report") && (
                            <Link
                                to="/transactions-report"
                                icon={<Summarize />}
                                label="Transactions Report"
                                open={open}
                                handleSelect={() =>
                                    handleListItemSelect("Transactions Report")
                                }
                            />
                        )}
                        {HasPermission("get all failed status") && (
                            <Link
                                to="/transactions-alert"
                                icon={<Error />}
                                label="Alert Transactions"
                                open={open}
                                handleSelect={() =>
                                    handleListItemSelect("Alert Transactions")
                                }
                            />
                        )}
                        {HasPermission("list notifications") && (
                            <Link
                                to="/notifications"
                                icon={<Notifications />}
                                label="Notifications"
                                open={open}
                                handleSelect={() =>
                                    handleListItemSelect("Notifications")
                                }
                            />
                        )}
                    </List>
                </Box>
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    backgroundColor: "#F8F8F8",
                    height: "100%",
                    justifyContent: "center",
                    maxWidth: open
                        ? `calc(100% - ${drawerWidth}px)`
                        : `calc(100% - 68px)`,
                }}
            >
                <DrawerHeader />
                <Outlet />
            </Box>
        </Box>
    )
}
