/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
    Avatar,
    Box,
    Divider,
    Grid,
    IconButton,
    Typography,
    Link,
    CircularProgress,
} from "@mui/material"
import { Download } from "@mui/icons-material"
import { useLocation, useParams } from "react-router-dom"
import { useQuery } from "react-query"
import html2canvas from "html2canvas"
import { DateTime } from "luxon"
import jsPDF from "jspdf"

import ride from "../../../Assets/RIDE_logo.png"
import ridePlus from "../../../Assets/ride_logo.png"
import rideFood from "../../../Assets/ride_food.jpg"
import hybridDesigns from "../../../Assets/hybridDesigns.jpg"
import CorporateRecieptInfo from "./corporateReceiptInfo.jsx"
import useBreakPoints from "../../../Hooks/useBreakPoints.js"
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js"
import ErrorPage from "../../../Component/errorPage.jsx"

const logo = [
    // { title: "RIDE", img: ride, width: 50, height: 35 },
    { title: "RIDE PLUS", img: ridePlus, width: 60, height: 60 },
    { title: "RIDE FOOD", img: rideFood, width: 57, height: 57 },
]
function DownloadCorporateReceipt() {
    const { transactionId } = useParams()
    const { pathname } = useLocation()
    const apiUrl = pathname.includes("custom-receipts")
        ? `system/receipt/custom/${transactionId}`
        : `system/receipt/company/${transactionId}`
    const { sm, md, xs, lg } = useBreakPoints()
    const receiptRef = useRef({
        style: {
            transform: lg
                ? "scale(1)"
                : md
                ? "scale(0.8)"
                : sm
                ? "scale(0.6)"
                : xs
                ? "scale(0.45)"
                : "scale(1)",
        },
    })
    const buttonRef = useRef({
        style: { transform: "scale(1)" },
    })
    const [isDownloading, setIsDownloading] = useState(false)

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isLoading, data, error } = useQuery(
        ["corporateReceipt", apiUrl],
        () => axiosPrivate.get(apiUrl)
    )
    const paymentInfo = useMemo(
        () => [
            {
                label: "Invoice No.",
                value: data?.data?.data?.receipt_number
                    ?.toString()
                    .padStart(8, "0"),
            },
            {
                label: "Customer Name",
                value: data?.data?.data?.company_name || data?.data?.data?.name,
            },
            {
                label: "Buyer's TIN",
                value:
                    data?.data?.data?.license_number ||
                    data?.data?.data?.tin_number,
            },
        ],
        [data?.data?.data]
    )
    const handleDownloadPDF = async () => {
        setIsDownloading(true)
        const receiptContainer = receiptRef.current
        const buttonElement = buttonRef.current

        // Store the original display value of the button
        const originalDisplay = buttonElement.style.display

        // Hide the button temporarily
        buttonElement.style.display = "none"

        const canvas = await html2canvas(receiptContainer, {
            allowTaint: true,
            useCORS: true,
            scale: window.devicePixelRatio,
        })

        // Restore the button's display
        buttonElement.style.display = originalDisplay

        // eslint-disable-next-line new-cap
        const pdf = new jsPDF("p", "mm", "a4")

        // Calculate the ratio to fit the content within the A4 dimensions
        const scaleFactor = 210 / canvas.width

        // Add the captured content to the PDF
        pdf.addImage(
            canvas,
            "PNG",
            0,
            0,
            canvas.width * scaleFactor,
            canvas.height * scaleFactor
        )

        // Save the PDF
        pdf.save(`corporate_receipt_${data?.data?.data?.receipt_number}`)
        setIsDownloading(false)
    }

    // Adjust zoom level when component mounts or screen size changes
    useEffect(() => {
        const zoomedContainer = receiptRef.current
        const handleResize = () => {
            if (lg) {
                zoomedContainer.style.transform = "scale(1)"
            } else if (md) {
                zoomedContainer.style.transform = "scale(0.8)"
            } else if (sm) {
                zoomedContainer.style.transform = "scale(0.6)"
            } else if (xs) {
                zoomedContainer.style.transform = "scale(0.45)"
            }
        }
        handleResize()
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [xs, sm, md, lg, receiptRef])
    useEffect(() => {
        const zoomedContainer = buttonRef.current
        const handleBtnResize = () => {
            if (lg) {
                zoomedContainer.style.transform = "scale(1)"
            } else if (md) {
                zoomedContainer.style.transform = "scale(1)"
            } else if (sm) {
                zoomedContainer.style.transform = "scale(1.1)"
            } else if (xs) {
                zoomedContainer.style.transform = "scale(1.2)"
            }
        }

        handleBtnResize()
        window.addEventListener("resize", handleBtnResize)
        return () => window.removeEventListener("resize", handleBtnResize)
    }, [xs, sm, md, lg, buttonRef])

    // RENDER
    if (error) return <ErrorPage error={error} />
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100vh",
                overflowX: "auto",
                padding: 1,
            }}
        >
            <div
                id="container"
                style={{
                    transformOrigin: "top center",
                }}
                ref={receiptRef}
            >
                <Box
                    sx={{
                        border: 2,
                        borderColor: "#fdbc3d",
                        bgcolor: "#fff",
                        p: 2,
                        m: xs ? "2em" : "0.5em",
                        position: "relative",
                        minWidth: "210mm", // A4 width
                        minHeight: "297mm", // A4 height
                    }}
                    id="a4-layout-container"
                >
                    {isLoading ? (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress
                                size={60}
                                sx={{
                                    position: "absolute",
                                    top: sm ? "25%" : "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        </Box>
                    ) : (
                        <>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                pb: 2,
                                                top: 6,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    position: "absolute",
                                                    ml: -2,
                                                    mt: -1,
                                                }}
                                            >
                                                <img
                                                    src={hybridDesigns}
                                                    alt="HYBRID"
                                                    style={{ height: 130 }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: "relative",
                                                    width: 120,
                                                    height: 70,
                                                    overflow: "hidden",
                                                    cursor: "pointer",
                                                    bgcolor: "#fff",
                                                }}
                                            >
                                                <Avatar
                                                    sx={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        width: "100%",
                                                        height: "100%",
                                                        bgcolor: "transparent",
                                                    }}
                                                    variant="square"
                                                >
                                                    <img
                                                        src={ride}
                                                        alt="Avatar"
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                        }}
                                                    />
                                                </Avatar>
                                            </Box>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                                fontWeight="bold"
                                                textAlign="center"
                                            >
                                                HYBRID DESIGNS PLC
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                // top: 6,
                                                left: 0,
                                                right: 0,
                                                pb: 2,
                                                // top: 6,
                                                mt: 2,
                                                // px: 2,
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    {logo?.map((item) => (
                                                        <Box
                                                            key={item.title}
                                                            sx={{
                                                                width: item.width,
                                                                height: item.height,
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                                mr: 2,
                                                            }}
                                                        >
                                                            <img
                                                                src={item.img}
                                                                alt=""
                                                                style={{
                                                                    maxWidth:
                                                                        "100%",
                                                                    maxHeight:
                                                                        "100%",
                                                                    objectFit:
                                                                        "contain",
                                                                }}
                                                            />
                                                        </Box>
                                                    ))}
                                                </Box>
                                                <Link
                                                    href="https://ride8294.com/"
                                                    underline="none"
                                                >
                                                    <Typography
                                                        textAlign="center"
                                                        variant="body2"
                                                        color="black"
                                                        sx={{ mt: -0.5, mr: 2 }}
                                                    >
                                                        www.ride8294.com
                                                    </Typography>
                                                </Link>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                            >
                                                <strong>TIN: </strong>0021283469
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                            >
                                                S/C.KIRKOS.W-02.H.NO.202/5TH
                                                FLOOR
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                            >
                                                LEBENZE BUILDING, GAZEBO SQUARE
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                            >
                                                ADDIS ABABA, ETHIOPIA
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                            >
                                                <strong>Tel: </strong>
                                                011-657-4866
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                            >
                                                <strong>Short Code: </strong>
                                                8294 / 6776
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Typography variant="body2" gutterBottom ml={1}>
                                    <strong>Date: </strong>
                                    {DateTime.fromISO(
                                        data?.data?.data?.created_at
                                    ).toFormat("dd/MM/yyyy HH:mm")}
                                </Typography>
                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <Divider color="#fdbc3d" />
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                        textAlign="center"
                                        fontWeight={900}
                                        sx={{
                                            my: 1,
                                        }}
                                    >
                                        Cash Invoice
                                    </Typography>
                                    <Divider color="#fdbc3d" sx={{ mb: 1 }} />
                                    {paymentInfo?.map((info) => (
                                        <Box
                                            key={info.label}
                                            sx={{ display: "flex" }}
                                        >
                                            <Box width="50%">
                                                <Typography
                                                    variant="body2"
                                                    gutterBottom
                                                >
                                                    {info.label}
                                                </Typography>
                                            </Box>
                                            <Box width="50%">
                                                <Typography
                                                    variant="body2"
                                                    gutterBottom
                                                >
                                                    {info.value}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CorporateRecieptInfo
                                        quantity={1}
                                        serviceCharge={Number(
                                            data?.data?.data?.service_charge
                                        )}
                                        amount={Number(
                                            data?.data?.data?.amount
                                        )}
                                        vat={Number(data?.data?.data?.vat)}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Box>
                <Box
                    id="download-button"
                    ref={buttonRef}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 2,
                        "@media print": {
                            display: "none",
                        },
                    }}
                >
                    <IconButton
                        size="small"
                        sx={{
                            bgcolor: "#fdbc3d",
                            color: "black",
                            ":hover": { bgcolor: "#fdbc3d" },
                            borderRadius: 5,
                            px: 2,
                            py: 1,
                            gap: 0.5,
                            width: 250,
                        }}
                        disabled={isDownloading}
                        onClick={handleDownloadPDF}
                    >
                        <Typography
                            variant="body2"
                            fontWeight={900}
                            textAlign="center"
                        >
                            Download Receipt
                        </Typography>
                        <Download />
                    </IconButton>
                </Box>
            </div>
        </Box>
    )
}

export default DownloadCorporateReceipt
