import React from "react"
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import { useMutation } from "react-query"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"

function GenerateReceipt(props) {
    const { open, setOpen, refetch } = props
    // HOOK FORM
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange" })

    const { enqueueSnackbar } = useSnackbar()

    const axiosPrivate = useAxiosPrivate()

    const { mutate, isLoading } = useMutation(
        (formData) => axiosPrivate.post(`/system/receipt`, formData),
        {
            onSuccess: () => {
                enqueueSnackbar(`Receipt generated succesfully.`, {
                    variant: "success",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                })
                refetch()
                setOpen(false)
            },
            onError: (error) => {
                if (error?.response?.data?.error?.field_error?.length > 0) {
                    error?.response?.data?.error?.field_error?.map((msg) =>
                        enqueueSnackbar(msg.description || "Network Error!", {
                            variant: "error",
                            preventDuplicate: true,
                            autoHideDuration: 2000,
                        })
                    )
                } else {
                    enqueueSnackbar(
                        error?.response?.data?.error?.message ||
                            error?.message ||
                            "Network Error!",
                        {
                            variant: "error",
                            preventDuplicate: true,
                            autoHideDuration: 2000,
                        }
                    )
                }
            },
        }
    )

    const onSubmit = (inputData) => {
        mutate(inputData)
    }

    return (
        <Dialog maxWidth="sm" open={open} onClose={() => setOpen(false)}>
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        {" "}
                        Generate Receipt
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        mx: 1,
                    }}
                >
                    <Controller
                        name="customer_name"
                        control={control}
                        rules={{
                            required: "Customer name is required.",
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Customer Name"
                                margin="normal"
                                fullWidth
                                error={!!errors?.customer_name}
                                helperText={
                                    errors?.customer_name
                                        ? errors.customer_name?.message
                                        : null
                                }
                                size="small"
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="tin_number"
                        control={control}
                        rules={{
                            required: "TIN is required.",
                            validate: (value) => {
                                if (value.length < 10) {
                                    return "TIN must be exactly 10 digits"
                                }
                                if (value.length > 10) {
                                    return "TIN must be exactly 10 digits"
                                }
                                return true
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="TIN"
                                margin="normal"
                                type="number"
                                fullWidth
                                error={!!errors?.tin_number}
                                helperText={
                                    errors?.tin_number
                                        ? errors.tin_number?.message
                                        : null
                                }
                                size="small"
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="amount"
                        control={control}
                        rules={{
                            required: "Amount is required.",
                            validate: (value) => {
                                if (value <= 0) {
                                    return "Amount must greater than 0"
                                }
                                return true
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Amount"
                                margin="normal"
                                type="number"
                                fullWidth
                                inputProps={{
                                    step: "0.01",
                                    min: "0",
                                }}
                                error={!!errors?.amount}
                                helperText={
                                    errors?.amount
                                        ? errors.amount?.message
                                        : null
                                }
                                size="small"
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="vat"
                        control={control}
                        rules={{
                            required: "VAT is required.",
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="VAT"
                                margin="normal"
                                type="number"
                                fullWidth
                                error={!!errors?.vat}
                                helperText={
                                    errors?.vat ? errors.vat?.message : null
                                }
                                size="small"
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="service_charge"
                        control={control}
                        rules={{
                            required: "Company service charge is required",
                            validate: (value) => {
                                if (value < 0) {
                                    return "Service charge must be a positive number"
                                }
                                return true
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                type="number"
                                inputProps={{
                                    step: "0.01",
                                    min: "0",
                                }}
                                label="Service Charge"
                                margin="normal"
                                fullWidth
                                error={!!errors?.service_charge}
                                helperText={
                                    errors?.service_charge
                                        ? errors.service_charge?.message
                                        : null
                                }
                                size="small"
                                {...field}
                            />
                        )}
                    />
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    mt: 1,
                    pr: 3,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={() => setOpen(false)}
                >
                    <Typography>Cancel</Typography>
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        bgcolor: "#fdbc3d",
                        ":hover": { bgcolor: "#fdbc3d" },
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoading}
                >
                    <Typography sx={{ color: "secondary.main" }}>
                        Submit
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "black",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default GenerateReceipt
