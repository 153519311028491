import { DateTime } from "luxon";

export const timeFormatter = (seconds = 0) => {
  const d = Number(seconds);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  const hDisplay =
    h > 0 ? `${h.toString().length > 1 ? `${h}` : `${0}${h}`}` : "00";
  const mDisplay =
    m > 0 ? `${m.toString().length > 1 ? `${m}` : `${0}${m}`}` : "00";
  const sDisplay =
    s > 0 ? `${s.toString().length > 1 ? `${s}` : `${0}${s}`}` : "00";
  return `${hDisplay}:${mDisplay}:${sDisplay}`;
};

// Find the difference between two datetimes
export const dateTimeDifference = (date1, date2) => {
  const dateTime1 = DateTime.fromISO(date1);
  const dateTime2 = DateTime.fromISO(date2);

  // Calculate the difference in minutes
  const differenceInMinutes = dateTime2.diff(dateTime1, 'minutes').minutes;

  // Convert to hours if greater than 60 minutes
  return differenceInMinutes >= 60
    ? `${Math.floor(differenceInMinutes / 60)} hr ${Math.round(differenceInMinutes % 60)} min`
    : `${Math.round(differenceInMinutes)} min`;

}

// display only 2 decimal places without rounding
export const roundToTwoDecimalPlaces = (number = 0) => {
  const numString = number.toString();
  const decimalIndex = numString.indexOf(".");
  if (decimalIndex === -1) {
    return number?.toLocaleString();
  }
  const num = numString.slice(0, decimalIndex + 3);
  return Number(num)?.toLocaleString();
};
// display only 3 decimal places without rounding
export const roundToThreeDecimalPlaces = (number = 0) => {
  const numString = number.toString();
  const decimalIndex = numString.indexOf(".");
  if (decimalIndex === -1) {
    return number?.toLocaleString();
  }
  const num = numString.slice(0, decimalIndex + 4);
  return Number(num)?.toLocaleString();
};

export function formatToTwoDecimalPlaces(value) {
  const roundedValue = Number(value).toFixed(2);
  const parts = roundedValue.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.') === "NaN" ? "--" : parts.join('.');
}

export function formatToThreeDecimalPlaces(value) {
  const roundedValue = Number(value).toFixed(3);
  const parts = roundedValue.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.') === "NaN" ? "--" : parts.join('.');
}
// Minute to 'hh:mm:ss' formatter
export const formatTime = (minutes) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = (minutes % 60) - (minutes % 1)
  const seconds = Math.floor((minutes % 1) * 60)
  const formattedTime = `${hours
    .toString()
    .padStart(2, "0")}:${remainingMinutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
  return formattedTime.includes("NaN") ? "--:--:--" : formattedTime
}
// Convert m/s to km/hr
export function convertMStoKMH(metersPerSecond) {
  const kilometersPerHour = (metersPerSecond * 3.6).toFixed(2);
  return kilometersPerHour;
}

// Get a date-time a week behind
export function getPreviousDateTime() {
  const currentDate = new Date();
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 7);

  const formattedDate = previousDate.toISOString();
  const dateTime = `${formattedDate.split("T")[0]}T00:00:00.000+03:00`;

  return dateTime;
}

// A default date a week behind
export const prevDate = DateTime.fromISO(getPreviousDateTime()).toFormat("yyyy-LL-dd")

export function formatString(inputString) {
  // Replace underscores with whitespace
  const stringWithSpaces = inputString.replace(/_/g, ' ');

  // Capitalize the first letter of each word
  const formattedString = stringWithSpaces.replace(/\b\w/g, match => match.toUpperCase());

  return formattedString;
}

export function splitNumberAndName(input) {
  let results = { tin: null, name: null };
  if (!input) return results;
  const cleanInput = input.replace(/[^a-zA-Z0-9\s]/g, '');
  const match = cleanInput.match(/.*?(\d+)\s*([^0-9].+?)?\s*$/);
  if (match) {
    const tinCandidate = match[1];
    const ownerName = match[2] ? match[2].trim() : null;
    results = {
      tin: tinCandidate.length === 10 ? tinCandidate : null,
      name: ownerName?.length > 8 ? ownerName : null
    };
  }
  return results;
}