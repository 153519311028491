import React from "react"
import { DateTime } from "luxon"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { Email, Phone } from "@mui/icons-material"
import { Box, Typography, Grid, Link, CircularProgress } from "@mui/material"

import axios from "../../Utils/Api/axios.js"
import logo from "../../Assets/RIDE_logo.png"
import ErrorPage from "../../Component/errorPage.jsx"
import { dateTimeDifference } from "../../Utils/dataFormat.js"

function TripSummary() {
    const { tripId } = useParams()

    const { data, error, isLoading } = useQuery(["trip-summary", tripId], () =>
        axios.get(`/trips/summery/${tripId}`)
    )

    if (isLoading)
        return (
            <Box
                sx={{
                    mt: 2,
                    display: "flex",
                    minHeight: "40vh",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CircularProgress />
            </Box>
        )
    if (error) {
        return <ErrorPage error={error} />
    }
    return (
        <Box
            sx={{
                px: { xs: 0, sm: 4 },
                bgcolor: "#F8F8F8",
                borderRadius: 2,
                boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
            }}
        >
            <Box
                sx={{
                    maxWidth: 600,
                    margin: "auto",
                    p: 2,
                }}
            >
                <Box
                    sx={{
                        padding: 3,
                        border: "1px solid #e0e0e0",
                        borderRadius: "8px",
                        mb: 3,
                        backgroundColor: "#fff",
                        gap: 2,
                    }}
                >
                    {/* Header */}
                    <Box sx={{ textAlign: "center", mb: "1.5rem" }}>
                        <img
                            src={logo}
                            alt="Logo"
                            style={{
                                width: 130,
                                height: 70,
                                marginBottom: "0.5rem",
                            }}
                        />
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: "bold",
                                mb: "1rem",
                                fontSize: "1.6rem",
                            }}
                        >
                            Trip Summary
                        </Typography>
                        <Typography variant="body1" textAlign="start">
                            You made an order on{" "}
                            {DateTime.fromISO(data?.data?.data?.pickup_time)
                                .toFormat("cccc LLLL dd, yyyy, hh:mm a z")
                                ?.replace("Africa/Addis_Ababa", "EAT")}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            maxHeight: {
                                xs: "calc(100vh - 460px)",
                                sm: "calc(100vh - 440px)",
                            },
                            overflowY: "auto",
                        }}
                    >
                        {/* Trip Details */}
                        <Box mt="1rem">
                            <Typography
                                variant="body2"
                                sx={{
                                    mb: 1,
                                    fontSize: "0.85rem",
                                    fontWeight: "bold",
                                }}
                            >
                                Paid
                            </Typography>
                            <Typography
                                variant="h5"
                                sx={{
                                    color: "#4caf50",
                                    fontWeight: "bold",
                                    mb: "1.5rem",
                                    fontSize: "1.6rem",
                                }}
                            >
                                {data?.data?.data.cost} ETB
                            </Typography>

                            {/* Distance and Duration */}
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: "0.85rem",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Distance
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{ fontSize: "1.1rem" }}
                                    >
                                        {Number(
                                            data?.data?.data?.distance ?? 0
                                        ).toFixed(2)}{" "}
                                        km
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: "0.85rem",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Duration
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{ fontSize: "1.1rem" }}
                                    >
                                        {dateTimeDifference(
                                            data?.data?.data?.pickup_time,
                                            data?.data?.data?.dropoff_time
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {/* Pickup and Drop-off */}
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: "bold",
                                    mb: "0.2rem",
                                    fontSize: "0.9rem",
                                }}
                            >
                                Pick-up at{" "}
                                {DateTime.fromISO(
                                    data?.data?.data?.pickup_time
                                ).toFormat("h:mm a")}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ mb: "1rem", fontSize: "0.9rem" }}
                            >
                                {data?.data?.data?.start_point_name}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: "bold",
                                    mb: "0.2rem",
                                    fontSize: "0.9rem",
                                }}
                            >
                                Dropoff at{" "}
                                {DateTime.fromISO(
                                    data?.data?.data?.dropoff_time
                                ).toFormat("h:mm a")}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ fontSize: "0.9rem" }}
                            >
                                {data?.data?.data?.end_point_name}
                            </Typography>
                        </Box>
                        {/* Vehicle Info */}
                        <Box mt="2rem">
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: "bold",
                                    mb: "1rem",
                                    fontSize: "1.6rem",
                                }}
                            >
                                Vehicle Info
                            </Typography>

                            {/* Vehicle Name */}
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: "0.9rem",
                                    mb: "0.2rem",
                                }}
                            >
                                Vehicle
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ fontSize: "1rem", mb: "1rem" }}
                            >
                                {data?.data?.data?.car?.model}
                            </Typography>

                            {/* Driver Name */}
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "bold",
                                    mb: "0.2rem",
                                }}
                            >
                                Driver
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ fontSize: "1rem", mb: "1rem" }}
                            >
                                {data?.data?.data?.driver_info?.first_name}{" "}
                                {data?.data?.data?.driver_info?.middle_name}{" "}
                                {data?.data?.data?.driver_info?.last_name}
                            </Typography>

                            {/* Service Type */}
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "bold",
                                    mb: "0.2rem",
                                }}
                            >
                                Service type
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ fontSize: "1rem" }}
                            >
                                Classic
                            </Typography>
                        </Box>
                        {/* Payment Info */}
                        <Box>
                            <Typography
                                variant="h5"
                                fontWeight="bold"
                                sx={{
                                    fontWeight: "bold",
                                    mb: "1rem",
                                    mt: "1.5rem",
                                    fontSize: "1.6rem",
                                }}
                            >
                                Payment
                            </Typography>
                            <Grid container spacing={2}>
                                {/* Left Column */}
                                <Grid item xs={12}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: "bold",
                                            mb: "0.2rem",
                                            fontSize: "0.9rem",
                                        }}
                                    >
                                        <strong>Billed to</strong>
                                    </Typography>
                                    <Typography>
                                        {data?.data?.data?.passenger_phone}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: "bold",
                                            mb: "0.2rem",
                                            fontSize: "0.9rem",
                                        }}
                                    >
                                        <strong>Ride cost</strong>
                                    </Typography>
                                    <Typography>
                                        {data?.data?.data?.cost} ETB
                                    </Typography>

                                    {/* <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: "bold",
                                            mb: "0.2rem",
                                            mt: "1rem",
                                            fontSize: "0.9rem",
                                        }}
                                    >
                                        <strong>Cost per hour</strong>
                                    </Typography>
                                    <Typography>210 ETB</Typography> */}

                                    {/* <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: "bold",
                                            mb: "0.2rem",
                                            mt: "1rem",
                                            fontSize: "0.9rem",
                                        }}
                                    >
                                        <strong>Payment type</strong>
                                    </Typography>
                                    <Typography>Paid with cash</Typography>

                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: "bold",
                                            mb: "0.2rem",
                                            mt: "1rem",
                                            fontSize: "0.9rem",
                                        }}
                                    >
                                        <strong>Rounding correction</strong>
                                    </Typography>
                                    <Typography>-0.74 ETB</Typography> */}
                                </Grid>

                                {/* Right Column */}
                                {/* <Grid item xs={6}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: "bold",
                                            mb: "0.2rem",
                                            fontSize: "0.9rem",
                                        }}
                                    >
                                        <strong>Cost per km</strong>
                                    </Typography>
                                    <Typography>16 ETB</Typography>

                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: "bold",
                                            mb: "0.2rem",
                                            mt: "1rem",
                                            fontSize: "0.9rem",
                                        }}
                                    >
                                        <strong>Flag-down fee</strong>
                                    </Typography>
                                    <Typography>130 ETB</Typography>

                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: "bold",
                                            mb: "0.2rem",
                                            mt: "1rem",
                                            fontSize: "0.9rem",
                                        }}
                                    >
                                        <strong>Total trip cost</strong>
                                    </Typography>
                                    <Typography>363 ETB</Typography>
                                </Grid> */}
                            </Grid>
                        </Box>

                        {/* Order Info */}
                        <Box>
                            <Typography
                                variant="h5"
                                fontWeight="bold"
                                sx={{
                                    fontWeight: "bold",
                                    mb: "1rem",
                                    mt: "1.5rem",
                                    fontSize: "1.6rem",
                                }}
                            >
                                Order Info
                            </Typography>
                            <Grid container spacing={2}>
                                {/* Issued On */}
                                <Grid item xs={12}>
                                    <Typography>
                                        <strong>Issued on</strong>
                                    </Typography>
                                    <Typography>
                                        {DateTime.fromISO(
                                            data?.data?.data?.dropoff_time
                                        )
                                            .toFormat(
                                                "cccc LLLL dd, yyyy, hh:mm a z"
                                            )
                                            ?.replace(
                                                "Africa/Addis_Ababa",
                                                "EAT"
                                            )}
                                    </Typography>
                                </Grid>

                                {/* Trip Billed On */}
                                <Grid item xs={12}>
                                    <Typography>
                                        <strong>Trip billed on</strong>
                                    </Typography>
                                    <Typography>
                                        {DateTime.fromISO(
                                            data?.data?.data?.dropoff_time
                                        )
                                            .toFormat(
                                                "cccc LLLL dd, yyyy, hh:mm a z"
                                            )
                                            ?.replace(
                                                "Africa/Addis_Ababa",
                                                "EAT"
                                            )}
                                    </Typography>
                                </Grid>

                                {/* Order ID */}
                                <Grid item xs={12}>
                                    <Typography>
                                        <strong>Order ID</strong>
                                    </Typography>
                                    <Typography>
                                        {data?.data?.data?.id}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                {/* Contact Info */}
                <Box sx={{ px: "1.5rem" }}>
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                        sx={{
                            fontWeight: "bold",
                            pb: "1.1rem",
                            mt: "2rem",
                            fontSize: "1.6rem",
                        }}
                    >
                        Contact Us
                    </Typography>
                    <Typography>
                        <strong>RIDE</strong>
                    </Typography>

                    <Link
                        href="tel:+251938483821"
                        underline="hover"
                        color="inherit"
                    >
                        <Box sx={{ display: "flex", mt: "0.5rem", gap: 1 }}>
                            <Phone
                                sx={{
                                    color: "#fdbc3d",
                                }}
                            />
                            <Typography textAlign="center">
                                +251938483821
                            </Typography>
                        </Box>
                    </Link>
                    <Link
                        href="mailto:tripsummary299@gmail.com"
                        underline="hover"
                        color="inherit"
                    >
                        <Box sx={{ display: "flex", mt: "0.5rem", gap: 1 }}>
                            <Email
                                sx={{
                                    color: "#fdbc3d",
                                }}
                            />
                            <Typography textAlign="center">
                                tripsummary299@gmail.com
                            </Typography>
                        </Box>
                    </Link>
                </Box>
            </Box>
        </Box>
    )
}

export default TripSummary
